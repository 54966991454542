import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export function IconTwitter(props: IconProps) {
    return (
        <Icon viewBox="0 0 30 24" width="30px" height="24px" {...props}>
            <g>
                <path
                    d="M29.5336 2.82767C28.4512 3.30598 27.2845 3.62954 26.0615 3.78429C27.3126 3.03869 28.2685 1.84291 28.7183 0.436108C27.5516 1.12544 26.2443 1.63189 24.8667 1.91325C23.7562 0.731536 22.1818 0 20.4388 0C17.0932 0 14.3802 2.71512 14.3802 6.06331C14.3802 6.54162 14.4365 6.99179 14.5349 7.44197C9.50248 7.18875 5.04644 4.78312 2.05231 1.11137C1.5322 2.01172 1.23701 3.05275 1.23701 4.16413C1.23701 6.26026 2.30533 8.11723 3.93594 9.20047C2.9379 9.17233 2.01014 8.89097 1.19484 8.4408V8.51114C1.19484 11.4513 3.27526 13.8992 6.05853 14.4478C5.55248 14.5885 5.01832 14.6589 4.45604 14.6589C4.06245 14.6589 3.68292 14.6166 3.31744 14.5463C4.09057 16.9519 6.32561 18.7104 8.96832 18.7526C6.88789 20.3845 4.28736 21.3552 1.44786 21.3552C0.955869 21.3552 0.477936 21.3271 0 21.2708C2.68487 23.0012 5.86174 24 9.29163 24C20.4247 24 26.5254 14.7573 26.5254 6.75264C26.5254 6.48535 26.5254 6.23212 26.5114 5.96483C27.6921 5.12075 28.7183 4.05158 29.5336 2.82767V2.82767Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    );
}
